import React, { useEffect, useRef, memo, useCallback } from 'react';
import { Badge, Typography, Paper, TextField, InputAdornment, Button, Alert } from '@mui/material';
import { ChatBubble, Face, Send, Done, DoneAll } from '@mui/icons-material';
import { useLocalStore, action, thunk, computed } from 'easy-peasy';
import clsx from 'clsx';
import { formatWithOptions, isSameDay } from 'date-fns/fp';
import { es } from 'date-fns/locale'
import { makeStyles } from '@mui/styles';
import HTMLRenderer from 'react-html-renderer'
import Section from '../core/Section';
import chatService from './services/ChatService';
import useNotifications from './services/NotificationHook';

const CHAT_SOURCE_OYDE = 1;
const CHAT_SOURCE_CLOUD = 2;

const MESSAGES_READ_EVENT = "MSG:RD";
const MESSAGE_NEW = "MSG:NEW";
const MESSAGE_NEW_SELF = "MSG:NEWSELF";

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  userContainer: {
    justifyContent: 'flex-end',
  },
  card: {
    maxWidth: '80%',
    backgroundColor: '#DCEAF5',
    marginBottom: '8px',

    borderRadius: '12px',
    padding: '8px',
    minWidth: '125px',
    // cursor: 'pointer',
    // transition: 'all .20s linear',
    // boxSshadow: '0px 1px 2px 0px rgba(0,0,0,0.4)',
    // '&:hover' : {
    //   boxShadow: '-1px 1px 9px 0px rgba(0,0,0,0.4)',
    // },
  },
  support: {
    backgroundColor: '#CAD8F8',
  },
  pending: {
    fontWeight: 'bold',
  }
});

const MemoizedChatMessage = memo(ChatMessage);

const Chat = ({context, entity, expand, onExpand}) => {
  
  const container = useRef();
  const { notifier } = useNotifications();
  const [state, actions] = useLocalStore(() => ({

    // STATE
    messages: [],
    loading: true,
    sending: false,
    error: "",
    text: "",
    unreads: computed((_state) => _state.messages.filter(m => m.source === CHAT_SOURCE_OYDE && !m.read).length),

    // ACTIONS
    setMessages: action((_state, messages) => { _state.messages = messages; }),
    addMessage: action((_state, message) => { _state.messages = [ ..._state.messages, message ]; }),
    markMessagesAsRead: action((_state) => {
      _state.messages = _state.messages.map(m => m.source !== CHAT_SOURCE_OYDE ? { ...m, read: true } : m );
    }),
    markSupportMessagesAsRead: action((_state) => {
      _state.messages = _state.messages.map(m => m.source === CHAT_SOURCE_OYDE ? { ...m, read: true } : m );
    }),
    setLoading: action((_state, payload) => { _state.loading = payload; }),
    setSending: action((_state, payload) => { _state.sending = payload; }),
    setError: action((_state, payload) => { _state.error = payload; }),
    setText: action((_state, text) => { _state.text = text; }),

    // THUNKS
    getMessages: thunk(async (_actions, {context, entity}) => {
      _actions.setLoading(true);
      _actions.setError();

      const response = await chatService.getMessages(context, entity);
      if (response.succeeded) {

        // console.log('messages', response)

        const messages = response.data;

        _actions.setMessages(messages);

        // Confirm read of provider messages if there are any
        if (messages.some(m => !m.read && m.source === CHAT_SOURCE_OYDE)) {

          await chatService.confirmRead(context, entity);
        }
      }
      else if (response.code === 403) {
        _actions.setError("Acceso no autorizado");
      }
      else {
        _actions.setMessages([]);
        // TODO: muestra error en pantalla...
        _actions.setError(response.message)
      }
      _actions.setLoading(false);
    }),

    sendNewMessage: thunk(async (_actions, {context, entity, text}) => {
      _actions.setSending(true);

      const response = await chatService.addMessage(context, entity, text);
      if (response.succeeded) {
        _actions.addMessage(response.data);
      }
      else {
        // TODO: muestra error en pantalla...
        console.log(response.message)
      }
      _actions.setSending(false);
      return response.succeeded || false ;
    }),

  }), [context, entity]);

  const messagesRead = useCallback(({ code, ctx, id }) => {

    if (context == ctx && entity == id) {

      console.log(code, `Context: ${ctx} Entity: ${id}`);
      actions.markMessagesAsRead();
    }
  });

  const messageReceived = useCallback(async (msg) => {

    if (context == msg.ctx && entity == msg.id) {

      msg.read = msg.read === "true";
      msg.source = Number(msg.source);
      
      actions.addMessage(msg);
      adjustScroll();
    }
  });

  const selfMessageReceived = useCallback(async (msg) => {

    if (context == msg.ctx && entity == msg.id) {

      msg.read = msg.read === "true";
      msg.source = Number(msg.source);
      
      actions.addMessage(msg);
      adjustScroll();
      await chatService.confirmRead(context, entity);
    }
  });

  useEffect(() => { 
    notifier.on(MESSAGES_READ_EVENT, messagesRead);
    notifier.on(MESSAGE_NEW, messageReceived);
    notifier.on(MESSAGE_NEW_SELF, selfMessageReceived);
    // notifier.on("MSG:TEST", (msg) => {
    //   msg.read = msg.read === "true";
    //   msg.source = Number(msg.source);
    //   console.log(msg);

    //   actions.addMessage(msg);
    // });

    return function cleanup() { 
      notifier.off(MESSAGES_READ_EVENT, messagesRead);
      notifier.off(MESSAGE_NEW, messageReceived);
      notifier.off(MESSAGE_NEW_SELF, selfMessageReceived);
    };
  }, []);

  useEffect(() => { 
    if(entity) {
      actions.getMessages({context, entity}).then(_ => {
        adjustScroll();
      });
    }
  }, [context, entity]);

  const onChatExpand = (expanded) => {
    onExpand(expanded);
    if (expanded && container.current) {
      adjustScroll();
    }
  }

  const adjustScroll = () => { if (container.current) container.current.scrollTop = container.current.scrollHeight; };

  const onSend = async () => {

    const succeeded = await actions.sendNewMessage({ context, entity, text: state.text });
    if (succeeded) {
      actions.setText("");
      adjustScroll();
    }
    else {
      console.log('Text sending failed')
    }
  }

  const onTextFocus = () => {
    actions.markSupportMessagesAsRead();
  }

  const { loading, sending, text, messages, unreads } = state;
  
  return (
    <Section title="MENSAJES" loading={loading} expand={expand} onExpand={onChatExpand}
      extra={<Badge badgeContent={unreads} variant="standard" color="error"><ChatBubble color="primary" /></Badge>}>

      {state.error ?
        <Alert severity="error">{state.error}</Alert> :
        <div style={{ height: 'calc(100vh - 250px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'stretch' }}>
        
          <div ref={container} style={{ overflowY: 'scroll' }}>

            {/* <SupportMessage text="Hola! Estamos aquí para ayudarte. Si tienes cualquier consulta puedes dejar un mensaje" />
            <UserMessage text="OK! Si tengo preguntas te contacto" />
            <UserMessage text="Muchas gracias" hideHeader />
            <SupportMessage text="Sólo tienes que dejar un mensaje por aquí" pending={true} /> */}

            {messages.map((msg, index) =>
              <MemoizedChatMessage key={index} message={msg} prevMessage={messages.length > 1 ? messages[index - 1] : null } />
            )}
            
          </div>

          <div style={{ marginTop: '16px', backgroundColor: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <TextField fullWidth={true} InputLabelProps={{ shrink: true }} multiline  
              // rowsMax={2}
              InputProps={{ 
                startAdornment: <InputAdornment position="start"><Face /></InputAdornment>,
                endAdornment: <InputAdornment position="end">
                  <Button size="small" style={{ marginBottom: '4px' }} variant="contained" color="primary" endIcon={<Send />}
                    onClick={onSend} disabled={sending || !text}> 
                    Enviar
                  </Button></InputAdornment> 
              }}
              placeholder="Escribe mensaje"
              value={text}
              onChange={e => actions.setText(e.target.value)}
              onFocus={onTextFocus}
            />

          {/* <Button
                variant="contained"
                color="primary"
                // className={classes.button}
                endIcon={<Send />}
              >
                Enviar
              </Button> */}
          </div>
        </div>
      }
      
    </Section>
  );
}
export default Chat;

function ChatMessage({message, prevMessage}) {

  const { text, read, author, source, timestamp } = message;
  const showAuthor = !prevMessage ? true : prevMessage.author !== author;
  const showDate = !prevMessage ? true : !isSameDay(new Date(timestamp), new Date(prevMessage.timestamp));

  const date = new Date(timestamp)
  const timeToString = formatWithOptions({ locale: es }, 'HH:mm');
  const dateToString = formatWithOptions({ locale: es }, 'd-LLL-yyyy');

  const classes = useStyles();
  const is_support = source === CHAT_SOURCE_OYDE; 

  return (
    <div className={clsx(classes.container, { [classes.userContainer]: !is_support })}>
      <Paper elevation={1} className={clsx(classes.card, { [classes.support]: is_support, [classes.pending]: is_support && !read })} style={{ backgroundColor: '#F0F8FF' }}>
        
        <div style={{ fontSize: '11px', fontWeight: 'normal', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#3F51B5', marginBottom: '4px' }}>
          {showAuthor && <div style={{ fontWeight: 'bold', marginRight: '16px' }}>{`${author}${is_support ? " (OYDE)" : ""}`}</div>}
          {!showAuthor && <div></div>}
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {showDate && <div style={{ marginRight: '8px' }}>{dateToString(date)}</div>}
            <div style={{ marginRight: '8px' }}>{timeToString(date)}</div>
            {!is_support && !read && <Done fontSize="small" htmlColor="gray" />}
            {!is_support && read && <DoneAll fontSize="small" htmlColor="green" />}
          </div>
        </div>
        <div style={{ fontWeight: is_support && !read ? 'bold' : 'normal' }}>
          <Typography variant="body2" sx={{ color: '#4B4B4B', pl: 0 }}>
            <HTMLRenderer html={"<span>" + text.replace(/(?:\r\n|\r|\n)/g, '<br>') + "</span>"} />
          </Typography>
        </div>
      </Paper>
    </div>
  );
}

function UserMessage({text, hideHeader}) {

  const classes = useStyles();

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Paper className={classes.card} elevation={1}>
        {!hideHeader && <div style={{ fontSize: '12px' }}><strong>David Jarque</strong> 15:12</div>}
        {text}
      </Paper>
    </div>
  );
}

function SupportMessage({text, pending}) {

  const classes = useStyles();

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <Paper className={clsx(classes.card, classes.support, { [classes.pending]: pending})} elevation={1}>
        <div><Typography color="primary" style={{ fontSize: '12px' }}><strong>OYDE Soporte</strong> 15:12</Typography></div>
        {text}
      </Paper>
    </div>
  );
}