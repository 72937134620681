// import {  } from 'react';
import { Typography, TextField, InputAdornment, Box } from '@mui/material';
// import {  } from '@mui/icons-material';


export function Title({text, style}) { // #6C6C6C
  return (
    <div style={{ margin: '4px 8px', backgroundColor: '#9C9C9C', borderRadius: '3px', ...style }}>
      <Typography style={{ margin: '0px 8px', fontWeight: 'bold', color: 'white' }}>{text}</Typography>
    </div>
  );
}

export function DetailItem({icon, title, content}) {
  return (
    <Box sx={{ p: 1 }}>
      <TextField 
        variant='standard' 
        fullWidth={true} 
        InputLabelProps={{ shrink: true }} 
        multiline 
        InputProps={{ 
          startAdornment: icon ? <InputAdornment position="start">{icon}</InputAdornment> : undefined,
          style: { fontSize: '14px' },
          disableUnderline: true
        }}
        label={title}
        value={content || ""} 
      />
    </Box>
  );
}

export function FormItem({icon, title, editable, disabled, value, onChange, onBlur, controls, type, style}) {
  return (
    <Box sx={{ p: 1 }}>
      <TextField 
        variant={editable ? 'outlined' : 'standard'} 
        size='small'
        fullWidth={true} 
        InputLabelProps={{ shrink: true }} 
        multiline 
        InputProps={{ 
          startAdornment: icon ? <InputAdornment position="start">{icon}</InputAdornment> : undefined,
          endAdornment: controls ? <InputAdornment position="end">{controls}</InputAdornment> : undefined,
          style: { fontSize: '14px', ...style }, // Change the font size here
        }}
        label={<span style={{ marginRight: '8px' }}>{title}</span>}
        disabled={disabled || false}
        value={value} 
        onChange={e => onChange && onChange(e.target.value)}
        onBlur={e => onBlur && onBlur(e.target.value)}
        type={type || 'text'}
      />
    </Box>
  );
}

// export function NumericItem({icon, title, value, onChange}) {
//   return (
//     <div style={{ padding: '8px' }}>
//       <TextField 
//         variant='standard' 
//         size='small'
//         fullWidth={true} 
//         InputLabelProps={{ shrink: true }} 
//         InputProps={{ 
//           startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
//           style: { fontSize: '14px' } // Change the font size here
//         }}
//         label={title}
//         value={value} 
//         onChange={e => onChange && onChange(e.target.value)}
//         type='number'
//       />
//     </div>
//   );
// }