// import './App.css';
import { StoreProvider, useStoreRehydrated } from 'easy-peasy';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import { store } from './store';
import { useEffect } from 'react';
import Shell from './Shell';
import useNotifications from './modules/core/services/NotificationHook';
// import _ from './http';


function WaitForStateRehydration({ children }) {
  const isRehydrated = useStoreRehydrated();
  const { startup } = useNotifications();

  useEffect(() => {
    if (isRehydrated) {
      console.log("REHYDRATED!!");

      startup();
    }
  }, [isRehydrated, startup]);

  return isRehydrated ? children : <span>LOADING...</span>;
}

function App() {

  const theme = createTheme();

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <WaitForStateRehydration>
            <Router>

              <Shell />

            </Router>
          </WaitForStateRehydration>
        </SnackbarProvider>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;