import React from 'react';
import { Grid, Typography, Chip, Badge, Tooltip, Avatar, Card, CardActionArea, Box, Stack, Divider, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Euro, People, Done, DoneAll, AccessTime, Attachment, PeopleAlt, MailOutline, NoPhotographyOutlined, CategoryOutlined, ChatBubbleOutline, FlightOutlined, PaymentOutlined as PaymentIcon, AccountCircle } from '@mui/icons-material';
import { useHistory } from "react-router-dom";
import { Paths } from '../../constants';
import ProductStatus from './ProductStatus';
import fileService from '../core/services/FileService';



// const dateWithoutYearFormatter = formatWithOptions({ locale: es }, 'd LLL');
// const dateWithYearFormatter = formatWithOptions({ locale: es }, 'd LLL yyyy');
// const timeFormatter = formatWithOptions({ locale: es }, 'HH:mm');
// const dateFlightFormatter = formatWithOptions({ locale: es }, 'HH:mm (d LLL)');



export default function ProductCard({product}) {

  const history = useHistory();
  const classes = useStyles();
  const stateColor = 'gray';

  const messages = 0;
  const unreadMessages = 0;

  const onOpen = () => {
    if (product.status === 'PRODUCT' || product.status === 'VALIDATED') {
      history.push(`${Paths.PROVIDERS_PRODUCTS}/${product.id}`);
    }
    else if (product.status === 'PROPOSAL' || product.status === 'REVIEWING' || product.status === 'REJECTED') {
      history.push(`${Paths.PROVIDERS_PROPOSALS}/${product.id}`);
    }
  };

  const peopleText = 2 === 1 ? "persona" : "personas";
  const imageUrl = product.image ? fileService.getPublicUrl(product.image) : undefined;

  return (
    <Grid item xs={12} lg={12}>
      <Card className={classes.item} elevation={2}  style={{ borderLeft: '0px solid gray', borderLeftColor: stateColor }}>
        <CardActionArea onClick={onOpen}>

          <Box display="flex" gap={2}>

            <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '267px', height: '150px', backgroundColor: 'lightgray', borderRadius: 1 }}>
              {!imageUrl ? 
              (
                <NoPhotographyOutlined sx={{ fontSize: '60px', color: 'white'}} />
              ) : (
                // <img src={imageUrl} alt={product.name} style={{ width: '150px', height: '150px' }} />
                  <img 
                    src={imageUrl} 
                    alt={product.name} 
                    style={{ 
                      width: '267px', // 150px height with 16:9 aspect ratio
                      height: '150px', 
                      objectFit: 'cover', 
                      objectPosition: 'center', 
                      borderRadius: '4px',
                      border: '1px solid #E0E0E0',
                    }} 
                  />
                )}
              </Box>

              <Box display="flex" flexDirection="column" justifyContent="space-between" flex={1} sx={{ minHeight: '150px' }}>
            




              <Box display="flex" justifyContent="space-between">

                <Box display="flex" flexDirection="column" sx={{ width: '40vw' }}>
                  <Typography variant="subtitle1" color="primary" noWrap sx={{ ml: 1, fontWeight: 'bold' }}>{product.name}</Typography>
                  <Typography variant="body2" color="GrayText" noWrap sx={{ ml: 1 }}>{product.description}</Typography>
                </Box>

                {!!product.duration && <Box display="flex" gap={2} mt={1} sx={{  }}>
                  <Chip className={classes.label} variant="outlined" icon={<AccessTime />} label={`DURACIÓN: ${product.duration}`} sx={{  }} />
                </Box>}

                
                {/* <Chip key={-1} style={{ margin: '3px 10px 3px 0px' }}
                  variant="outlined"
                  size="small"
                  avatar={<Avatar>1</Avatar>}
                  label={<span>Pago de cliente de <strong>{`10 €`}</strong></span>}
                /> */}

              </Box>





              <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
                {(product.options || []).map((option, idx) => (
                  <CardOptionView key={idx} option={option} />
                ))}
              </Stack>
  

              <div style={{ backgroundColor: '#F6F6F6', width: '100%', height: '34px', borderRadius: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>

                {/* <Stack direction="row" spacing={1} sx={{ ml: 1 }}>
                  {product.status === 'PRODUCT' && <DoneAll fontSize="small" color="success" />}
                  {product.status === 'VALIDATED' && <Done fontSize="small" color="success" />}
                  <Typography variant="body2" color="GrayText" sx={{ color: product.status !== 'REJECTED' ? '#5B5B5B' : '#D32E30', fontWeight: 'bold' }}>{statusTitle[product.status]}</Typography>
                  <Typography variant="caption" color="GrayText" sx={{ color: '#5B5B5B' }}>{statusSubtitle[product.status]}</Typography>
                </Stack> */}
                <ProductStatus product={product} showInfo />
                
                
              
                
                <Box display="flex" gap={3} sx={{ mr: 2 }}>

                  {/* <Button variant='text' size='small' color='error' onClick={e => e.preventDefault()}>Eliminar</Button> */}

                  
                  <Box display="flex" alignItems="center">
                    <Attachment color="info" />
                    <Typography variant="body2" color="GrayText" sx={{ color: '#5B5B5B', ml: 1 }}>{product.attachmentsQty || 0} DOCUMENTOS ADJUNTOS</Typography>
                  </Box>
                  

                  {messages > 0 && <BootstrapTooltip title={`Chat con mensajes${unreadMessages > 0 ? " pendientes de leer" : ""}`}>
                      <Box display="flex" alignItems="center" size="small" style={{  }}>
                        <Badge badgeContent={unreadMessages} variant="standard" color="error">
                          {/* <ChatBubbleOutline color="primary" /> */}
                          <Box display="flex" alignItems="center">
                            <MailOutline color="info" />
                            <Typography variant="body2" color="GrayText" sx={{ color: '#5B5B5B', ml: 1 }}>MENSAJES</Typography>
                          </Box>
                        </Badge>
                      </Box>
                  </BootstrapTooltip>}
                  
                </Box>
              </div>

            </Box>
          </Box>

        </ CardActionArea>
      </Card>
    </Grid>
  );
};

// function OptionView() {

//   return (
//     <Stack spacing={0}>
//       <Typography variant="caption" color="GrayText" sx={{ color: '#5B5B5B', ml: 1, fontWeight: 'bold' }}>OPCIÓN A</Typography>
//       <Stack direction="row" spacing={1}>
//         <Chip size="small" variant="outlined" label="15€/Grupo" />
//         <Chip size="small" variant="outlined" label="PAX: ≥ 15" />
//       </Stack>
//     </Stack>
//   );
// }


const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

function CardOptionView({ option }) {

  const { minPax, maxPax } = option;
  let paxText;

  if (minPax === 0 && maxPax === 0) {
    paxText = undefined;
  }
  else if (minPax === maxPax) {
    paxText = `${minPax}`;
  }
  else if (minPax === 0 && maxPax > 0) {
    paxText = `Max ${maxPax}`;
  }
  else if (minPax > 0 && maxPax > 0) {
    paxText = `${minPax} a ${maxPax}`;
  }
  else if (minPax > 0 && maxPax === 0) {
    paxText = `Min ${minPax}`;
  }

  return (
    <Stack spacing={0}>
      <Typography variant="caption" color="GrayText" noWrap sx={{ color: '#5B5B5B', ml: 1, fontWeight: 'bold', maxWidth: '250px' }}>{option.name}</Typography>
      <Stack direction="row" spacing={1}>
        {/* <Chip size="small" variant="outlined" label={`${option.price}€ / ${option.priceType === 'GROUP' ? 'grupo' : 'persona'}`} /> */}
        <Chip style={{ margin: '3px 10px 3px 0px' }}
          variant="outlined"
          size="small"
          // icon={<Euro />}
          avatar={<Avatar><Euro fontSize='14px' /></Avatar>}
          label={`${option.price}€ / ${option.priceType === 'GROUP' ? 'grupo' : 'persona'}`}
        />
        {/* {paxText !== undefined && <Chip size="small" variant="outlined" label={paxText} />} */}
        {paxText !== undefined && <Chip style={{ margin: '3px 10px 3px 0px' }}
          variant="outlined"
          size="small"
          // icon={<People />}
          avatar={<Avatar><People fontSize='14px' /></Avatar>}
          label={paxText}
        />}
        {/* <Chip size="small" variant="outlined" label="PAX: ≥ 15" /> */}
      </Stack>
    </Stack>
  );
}

const useStyles = makeStyles((theme) => ({
  item: {
    cursor: 'pointer',
    padding: '8px', 
    transition: 'all .20s linear',
    boxSshadow: '0px 1px 2px 0px rgba(0,0,0,0.4)',
    '&:hover' : {
      boxShadow: '-1px 1px 9px 0px rgba(0,0,0,0.4)',
    },
    // '&$itemHeader': {
    //   '&:hover' : {
    //     backgroundColor: '#dfe7fd',
    //   },
    // },
  },
  itemHeader: {
    backgroundColor: '#F5F5F5', 
    borderRadius: '5px 5px 0px 0px',
    borderBottom: '1px solid white',

    transition: 'all .20s linear',
    '&:hover' : {
      backgroundColor: '#dfe7fd', // faf3dd
      // backgroundColor: '#faf3dd',
    },
  },
  link: {
    marginRight: '10px',
    // color: 'darkorange',
  },
  logo: {
    height: '34px',
    margin: '10px',
  },

  card: {
    display: 'flex',
  },
  cardContent: {
    width: '100%',
    padding: 0, //padding: '0 0 0 8px',
    // paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  label: {
    // borderRadius: '3px',
    border: '0px',
    marginRight: '8px',
    // color: '#5B5B5B',
    maxWidth: '100%',
  },
  tag: {
    borderRadius: '3px',
    // border: '0px',
    marginRight: '8px',
    color: '#5B5B5B',
  }
}));