import { useEffect } from 'react';
import { Box, Grid, useMediaQuery, Button, Stack, Divider, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { SentimentDissatisfiedOutlined } from '@mui/icons-material';
import { makeStyles, useTheme } from '@mui/styles';
import { useHistory } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import ProductCard from './ProductCard';
import Empty from '../core/Empty';
import { Paths } from '../../constants';

export default function ProductList() {

  const history = useHistory();
  
  const { filteredProducts, realProducts, loading } = useStoreState((state) => state.products);
  const { loadProducts, generateEmptyProposal } = useStoreActions((state) => state.products);

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    loadProducts({ force: false });
  }, []);

  const onNewProduct = async () => { 
    const proposalId = await generateEmptyProposal();
    if (proposalId) {
      history.push(`${Paths.PROVIDERS_PROPOSALS}/${proposalId}`);
    }
  }

  return (
    <>
    {/* <style>
      {`
        #scroller::-webkit-scrollbar {
          width: 5px;
        }
        #scroller::-webkit-scrollbar-thumb {
          background: transparent;
          border-radius: 4px;
          opacity: 0;
        }
        #scroller::-webkit-scrollbar-track {
          background: transparent;
        }
        #scroller:hover::-webkit-scrollbar-thumb {
          background: #B2B2B2;
        }
        #scroller:hover::-webkit-scrollbar-track {
          background: transparent;
        }
      `}
    </style> */}
    <Box sx={{ flex: 1, mt: 0, p: 2, pt: 0 }}>

      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ p: 1, pb: 3, backgroundColor: 'white' }}>
        <Stack direction="row" spacing={3} alignItems="center">
          {/* <Typography variant="subtitle1" style={{ color: '#5B5B5B' }}>{realProducts?.length || 0} productos en catálogo</Typography> */}
          <Typography variant="subtitle1" style={{ color: '#5B5B5B' }}>Catálogo</Typography>
          <Divider orientation="vertical" flexItem />
          <ProductsFilter />
        </Stack>
        <Button variant="contained" color="primary" onClick={onNewProduct}>Crear producto</Button>
      </Box>

      

      {(filteredProducts?.length || 0) > 0 ? (
        <Grid item container pb={1} pr={1} spacing={isMobile? 1 : 3} wrap="wrap" alignContent='flex-start' className={classes.contentArea}>
          {filteredProducts.map((product, idx) => (
            <ProductCard key={idx} product={product} />
          ))}
        </Grid>
        ) : (
          <>
          {!loading && <Empty title="No se han encontrado ítems" subtitle="Cambia el filtro o crea una nueva propuesta de producto" />}
          </>
        )
      }

      {/* {!totalBookings && !loading && <Grid item container justify="center" style={{ paddingTop: '50px' }}>
        <Typography variant="h5" color="primary">Sin resultados</Typography>
      </Grid>} */}

      {/* {loading && <Grid item container justify="center" style={{ paddingTop: '50px' }}>
        <CircularProgress color="inherit" />
      </Grid>} */}


    </Box>
    </>
  );
}

function ProductsFilter() {

  const { filter } = useStoreState((state) => state.products);
  const { setFilter } = useStoreActions((state) => state.products);

  useEffect(() => {
    if (!filter.category) {
      setFilter({ category: 'PRODUCT' });
    }
  }, []); 

  return (
    <Stack direction="row" spacing={0} alignItems="center">
      <FilterButton category="PRODUCT" value={filter.category} onChange={c => setFilter({ ...filter, category: c ? 'PRODUCT' : undefined })} />
      <FilterButton category="PROPOSAL" value={filter.category} onChange={c => setFilter({ ...filter, category: c ? 'PROPOSAL' : undefined })} />
      <FilterButton category="REVIEWING" value={filter.category} onChange={c => setFilter({ ...filter, category: c ? 'REVIEWING' : undefined })} />
      <FilterButton category="REJECTED" value={filter.category} onChange={c => setFilter({ ...filter, category: c ? 'REJECTED' : undefined })} />
      <FilterButton category="VALIDATED" value={filter.category} onChange={c => setFilter({ ...filter, category: c ? 'VALIDATED' : undefined })} />
    </Stack>
  );
}

const filterTitles = {
  'PROPOSAL': 'Borradores',
  'REVIEWING': 'En revisión',
  'REJECTED': 'Rechazadas',
  'VALIDATED': 'Por confirmar',
  'PRODUCT': 'Productos',
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 0,
    border: `3px solid ${theme.palette.background.default}`,
    padding: '1px',
    color: 'white',
    backgroundColor: '#1876D2',
    fontSize: '10px',
  },
}));

function FilterButton({ category, count, value, onChange }) {
  
  const { products } = useStoreState((state) => state.products);
  const total = (products || []).filter(p => p.status === category).length;

  return (
    <Button variant={category === value ? 'outlined' : 'text' } color="info" sx={{ textTransform: 'none', minWidth: '130px' }}
      onClick={() => onChange(!(category === value))}
      disabled={total === 0}
      >
        {/* <span style={{ fontSize: '12px' }}>{filterTitles[category]}<span style={{ marginLeft: '5px', fontSize: '10px', color: 'gray' }}>{`${total >= 0 ? `(${total})` : ''}`}</span></span> */}
      <StyledBadge badgeContent={total}>
        {/* {`${filterTitles[category]} ${total > 0 ? `(${total})` : ''}`} */}
        {filterTitles[category]}
      </StyledBadge>
    </Button>
  );
}

const useStyles = makeStyles((theme) => ({
  contentArea: {
    // xs={12} sm={6} md={6} lg={2}
    // width: '100%',
    overflow: 'scroll',
    [theme.breakpoints.only('xs')]: {
      height: 'calc(100vh - 120px)', 
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: 'calc(100vh - 290px)',
    },
    [theme.breakpoints.only('lg')]: {
      height: 'calc(100vh - 170px)',
    },
  },
}));