import { GET, POST, PUT, DELETE } from '../../../http';
import { store } from '../../../store';

const UNKNOWN_ERROR = { code: 0, message: 'Error desconocido', succeeded: false };

class ProductService {

  // PRODUCTS
  getProducts = () => GET(`/providers/${getProvider()}/products`, []);
  getProduct = (productId) => GET(`/providers/${getProvider()}/products/${productId}`);
  confirmProduct = (productId) => GET(`/providers/${getProvider()}/products/${productId}/confirm`);
  updateProductAttachments = (productId, attachments) => PUT(`/providers/${getProvider()}/products/${productId}/attachments`, { attachments });

  // PROPOSALS
  generateEmptyProposal = () => GET(`/providers/${getProvider()}/proposals/new`);
  getProposals = () => GET(`/providers/${getProvider()}/proposals`, []);
  getProposal = (proposalId) => GET(`/providers/${getProvider()}/proposals/${proposalId}`);
  reviewProposal = (proposalId) => GET(`/providers/${getProvider()}/proposals/${proposalId}/review`);
  createProposal = (data) => POST(`/providers/${getProvider()}/proposals`, data);
  updateProposal = (data) => PUT(`/providers/${getProvider()}/proposals`, data);
  deleteProposal = (proposalId) => DELETE(`/providers/${getProvider()}/proposals/${proposalId}`);
  organizeUnsavedFiles = (proposalId, attachments) => PUT(`/providers/${getProvider()}/proposals/${proposalId}/unsavedfiles`, { attachments });
}

function getProvider() {
  return store.getState().auth.providerId || 0;
}

export default new ProductService();